





import Vue from 'vue'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'

@Component
export default class MetaComponent extends Vue {
  @State('currentScreenWidth') screenWidth: number
  @State('isContentLoading') isContentLoading: boolean
  @State('isPageLoading') isPageLoading: boolean

  @Mutation('setPageLoading')
  public setPageLoad: (pageLoad: boolean) => void

  @Mutation('setContentLoading')
  public setContentLoad: (contentLoad: boolean) => void

  get hideTemplate (): boolean {
    return this.isContentLoading && this.isPageLoading
  }
}
