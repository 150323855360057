







































































































































































import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { DELETE_ACCOUNT, GET_ACCOUNT_SETTINGS, SET_ACCOUNT_SETTINGS, SET_PASSWORD } from '@/store/user/actions.type'
import { SimpleServerResponse, User } from '@/store/types'
import { AccountSettings } from '@/store/user/types'
import { checkPassword } from '@/common/globals'
import { GET_PROFILE_STUDENT_SETTINGS, SET_PROFILE_STUDENT_SETTINGS } from '@/store/student/actions'
import { StudentProfileSettings } from '@/store/student/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { ConfirmationModal }
})
export default class Settings extends MetaComponent {
  @Action(GET_ACCOUNT_SETTINGS)
  public getAccountSettings: () => Promise<SimpleServerResponse>

  @Action(SET_ACCOUNT_SETTINGS)
  public setAccountSettings: (params: AccountSettings) => Promise<SimpleServerResponse>

  @Action(GET_PROFILE_STUDENT_SETTINGS)
  public getProfileStudentSettings: () => Promise<SimpleServerResponse>

  @Action(SET_PROFILE_STUDENT_SETTINGS)
  public setProfileStudentSettings: (params: StudentProfileSettings) => Promise<SimpleServerResponse>

  @Action(DELETE_ACCOUNT)
  public confirmDeleteAccount: () => Promise<SimpleServerResponse>

  @Action('logout')
  public LOGOUT: () => Promise<boolean>

  @Action(SET_PASSWORD)
  public setPassword: (params: object) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  accountSettings: AccountSettings = {
    receiveEmails: false
  }

  studentProfileSettings: StudentProfileSettings = {
    dontMatchCompetences: false
  }

  passwordPatternIncorrect = ''
  passwordDoesntMatch = ''
  feedbackHeadline = ''
  feedbackInfo = ''
  responseState = false
  password = ''
  newPassword = ''
  confirmNewPassword = ''
  isRegexAccordant: boolean | null = null
  isPasswordAccordant: boolean | null = null

  get hasRightMatch (): boolean {
    return hasRight(Rights.STUDENT_OWN_TARGET_JOB_AND_COMPETENCES_WRITE)
  }

  public saveSettings (): void {
    const countOfPromises = this.hasRightMatch ? 2 : 1
    let executedPromises = 0

    this.$root.$emit('dismiss-alert')
    this.$root.$emit('load')
    this.setAccountSettings(this.accountSettings).then(() => {
      this.feedbackInfo = ''
      this.feedbackHeadline = this.$t('info.save-success', { what: this.$t('main-menu.settings') }).toString()
      this.responseState = true
    }, error => {
      this.feedbackHeadline = this.$i18n.t('warning.error!').toString()
      this.feedbackInfo = error.message
      this.responseState = false
    }).finally(() => {
      executedPromises++
      if (countOfPromises === executedPromises) {
        this.$root.$emit('end-load')
      }
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
    })
    if (this.hasRightMatch) {
      this.setProfileStudentSettings(this.studentProfileSettings).then(() => {
        this.feedbackInfo = ''
        this.feedbackHeadline = this.$t('info.save-success', { what: this.$t('main-menu.settings') }).toString()
        this.responseState = true
      }, error => {
        this.feedbackHeadline = this.$i18n.t('warning.error!').toString()
        this.feedbackInfo = this.$i18n.t('settings.' + error.message).toString()
        this.responseState = false
      }).finally(() => {
        executedPromises++
        if (countOfPromises === executedPromises) {
          this.$root.$emit('end-load')
        }
        this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
      })
    }
  }

  public saveNewPassword (): void {
    if (!this.isPasswordAccordant || !this.isRegexAccordant) {
      return
    }

    const params = { username: this.userGetter.username, password: this.password, newPassword: this.newPassword }

    this.$root.$emit('dismiss-alert')
    this.$root.$emit('load')
    this.setPassword(params).then(() => {
      this.feedbackHeadline = this.$t('info.changed-success', { what: this.$t('settings.password-changed') }).toString()
      this.feedbackInfo = ''
      this.responseState = true
    }, error => {
      this.feedbackHeadline = this.$i18n.t('warning.error!').toString()
      this.feedbackInfo = this.$i18n.t('settings.' + error.message).toString()
      this.responseState = false
    }).finally(() => {
      this.$root.$emit('alert', this.feedbackHeadline, this.feedbackInfo, !this.responseState)
      this.$root.$emit('end-load')
    })
  }

  created (): void {
    this.$root.$emit('load')
    this.getAccountSettings().then((data) => {
      this.accountSettings = data.content
      if (this.hasRightMatch) {
        this.getProfileStudentSettings().then((data) => {
          this.studentProfileSettings = data.content
        }).catch(error => {
          this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('settings.' + error.message), true)
        }).finally(() => {
          this.$root.$emit('end-load')
        })
      }
    }).catch(error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('settings.' + error.message), true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })

    this.passwordDoesntMatch = this.$i18n.t('settings.password-doesnt-match').toString()
    this.passwordPatternIncorrect = this.$i18n.t('settings.password-pattern-incorrect').toString()
  }

  regexCheck (): void {
    if (!this.newPassword.length) {
      this.isRegexAccordant = null
    } else {
      this.isRegexAccordant = checkPassword(this.newPassword)
    }
  }

  checkPasswordAccordance (): void {
    if (!this.confirmNewPassword.length) {
      this.isPasswordAccordant = null
    } else {
      this.isPasswordAccordant = (this.newPassword === this.confirmNewPassword) && this.newPassword.length > 0
    }
  }

  deleteAccount (): void {
    this.$root.$emit('load')
    this.confirmDeleteAccount().then(() => {
      this.LOGOUT().finally(() => {
        this.$router.push({ name: 'home' })
      })
      this.$root.$emit('alert', this.$i18n.t('info.account-deletion-success'), this.$i18n.t('info.success'))
    }).catch(error => {
      this.$root.$emit('alert', this.$i18n.t('settings.' + error.message), this.$i18n.t('warning.error!'), true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }
}
